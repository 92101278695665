import { profileTransactions } from ".";
import { apiUrl, _axios_base_get_list } from "..";

export default async function getProfileTransactions(limit, skip, query = {}) {
  return await _axios_base_get_list(
    `${apiUrl}/${profileTransactions}/getProfileTransactions`,
    limit,
    skip,
    query
  );
}
