import { navigate, useLocation } from "@reach/router";
import { Pagination, Skeleton, Spin, Tabs } from "antd";
import { Link } from "gatsby";
import { find, get, isArray, isEmpty, map, set, some, toNumber } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Textfit } from "react-textfit";
import { not_foundIcon } from "../../../images";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import { defaultPopulatedFieldName } from "../../../newApi";
import getPairingUser from "../../../newApi/pairing/getPairingUser";
import getUserTransactions from "../../../newApi/pairing/getUserTransactions";
import getProfiles from "../../../newApi/profile/getProfiles";
import getProfileTransactions from "../../../newApi/profileTransactions/getProfileTransactions";
import { triggerModal } from "../../../redux/actions/app-actions";
import { routes } from "../../../route";
import { formatDecimalNumber } from "../../../utilities/common-function";
import {
  placementTransactionDesc,
  profileTransactionsType,
} from "../../../utilities/profileTransactions";
import { numberDecimal } from "../../../utilities/startUp";
import FilterModal from "../../general/components/FilterModal";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";
import { getFormatedDate } from "../config";

let timeoutFunc;
// markup
const PAGESIZE = 10;
const RobotPage = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  // const [form] = useForm();
  // const [loading, setLoading] = useState(false);
  const [arrayLoading, setArrayLoading] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [logType, setLogType] = useState("all");
  const [dataPagination, setDataPagination] = useState({});
  const [logTitle, setLogTitle] = useState();
  // const [validDays, setValidDays] = useState([]);
  const accessKey = get(props.user, "accessKey");
  const user = get(props, "user.user");
  const [profile, setProfile] = useState({});
  const [pairingUser, setPairingUser] = useState({});

  const tabItems = [
    {
      text: t("deposit", sourceKey.asset),
      textEn: "Deposit",
      path: routes.deposit.to(),
    },
    {
      text: t("withdrawal", sourceKey.asset),
      textEn: "Withdrawal",
      path: routes.withdrawal.to(),
    },
    {
      text: t("transfer", sourceKey.asset),
      textEn: "Inter-Transfer",
      path: routes.interTransfer.to(),
    },
    // {
    //   text: "激活码",
    //   textEn: "Activation Code",
    //   path: routes.activationCode.to(),
    // },
  ];

  const detailsItem = (content) => {
    return (
      <div className="flex items-center ">
        <span className="inline-block text-xs mr-2">
          {get(content, "title")}:
        </span>
        <span className="inline-block text-xs font-semibold">
          {" "}
          {get(content, "value")}
        </span>
      </div>
    );
  };

  function renderRecordDetails(item) {
    let type = get(item, "sourceType");
    let content = {};
    switch (type) {
      case 0:
        content = {
          title: t("placement", sourceKey.pairing),
          value: get(item, `${defaultPopulatedFieldName("topUpId")}.username`),
        };
        return detailsItem(content);
      case 1:
        content = {
          title: t("username", sourceKey.pairing),
          value: get(
            item,
            `${defaultPopulatedFieldName(
              "bonusId"
            )}.${defaultPopulatedFieldName("buyerId")}.username`
          ),
        };
        return detailsItem(content);
      case 2:
        content = {
          title: t("placement", sourceKey.pairing),
          value: get(
            item,
            `${defaultPopulatedFieldName(
              "bonusPairingId"
            )}.${defaultPopulatedFieldName("placementId")}.username`
          ),
        };
        return detailsItem(content);
      case 3:
        content = {
          title: t("username", sourceKey.pairing),
          value: get(
            item,
            `${defaultPopulatedFieldName(
              "bonusLeaderId"
            )}.${defaultPopulatedFieldName("distributorId")}.username`
          ),
        };
        return detailsItem(content);
      case 8:
        content = {
          title: t("from", sourceKey.pairing),
          value: get(
            item,
            `${defaultPopulatedFieldName(
              "transferId"
            )}.${defaultPopulatedFieldName("senderId")}.username`
          ),
        };
        return detailsItem(content);
      case 9:
        content = {
          title: t("to", sourceKey.pairing),
          value: get(
            item,
            `${defaultPopulatedFieldName(
              "transferId"
            )}.${defaultPopulatedFieldName("receiverId")}.username`
          ),
        };
        return detailsItem(content);
      case 10:
        content = {
          title: t("vipType", sourceKey.pairing),
          value: "6",
        };
        return detailsItem(content);
      case 11:
        content = {
          title: t("vipType", sourceKey.pairing),
          value: "7",
        };
        return detailsItem(content);

      default:
        break;
    }
  }

  const filterItems = [
    {
      labelCn: "充值",
      label: t("deposit", sourceKey.asset),
      value: "deposit",
      getData: async (skip) => {
        return await getProfileTransactions(PAGESIZE, skip, {
          sourceType: profileTransactionsType.deposit,
          profileId: get(profile, "_id"),
          trxType: "IN",
          depositIsAdminTopUp: 0,
        });
      },
      render: (data) => {
        return map(data, (item, index) => {
          return (
            <div className={`pb-1`} key={index}>
              <div className=" flex justify-between items-center">
                <span className="inline-block font-bold text-md text-green-500">
                  {/* 充值  */}
                  {t("deposit", sourceKey.asset)}
                </span>
                <span className="inline-block font-bold text-md text-green-500">
                  +{formatDecimalNumber(get(item, `amount`), numberDecimal)}{" "}
                  USDT
                </span>
              </div>
              <div className="flex items-center ">
                <span className="inline-block text-xs mr-2">
                  {getFormatedDate(get(item, "createdAt"), true)}
                </span>
              </div>
            </div>
          );
        });
      },
    },
    {
      labelCn: "提币",
      label: t("withdrawal", sourceKey.asset),
      value: "withdrawal",
      getData: async (skip) => {
        return await getProfileTransactions(PAGESIZE, skip, {
          sourceTypeIn: [
            profileTransactionsType.withdrawal,
            profileTransactionsType.withdrawalServiceCharge,
          ],
          profileId: get(profile, "_id"),
          trxType: "OUT",
        });
      },
      render: (data) => {
        return map(data, (item, index) => {
          return (
            <div className={`pb-1 `} key={index}>
              <div className=" flex justify-between items-center">
                <span className="inline-block font-bold text-md text-red-500">
                  {/* 提币  */}
                  {get(item, "sourceType") ===
                  profileTransactionsType.withdrawal
                    ? t("withdrawal", sourceKey.asset)
                    : t("serviceCharge2", sourceKey.asset)}
                </span>
                <span className="inline-block font-bold text-md text-red-500">
                  -
                  {formatDecimalNumber(
                    toNumber(get(item, "amount")),
                    numberDecimal
                  )}{" "}
                  USDT
                </span>
              </div>
              <div className="flex items-center ">
                <span className="inline-block text-xs mr-2">
                  {getFormatedDate(get(item, "createdAt"), true)}
                </span>
              </div>
            </div>
          );
        });
      },
    },
    {
      labelCn: "互转",
      label: t("transfer", sourceKey.asset),
      value: "transfer",
      getData: async (skip) => {
        return await getProfileTransactions(PAGESIZE, skip, {
          sourceType: profileTransactionsType.transfer,
          profileId: get(profile, "_id"),
        });
      },
      render: (data) => {
        return map(data, (item, index) => {
          let isSender =
            get(
              item,
              `${defaultPopulatedFieldName("transferTransactionId")}.senderId`
            ) === get(profile, "_id");
          let isReceiver =
            get(
              item,
              `${defaultPopulatedFieldName("transferTransactionId")}.receiverId`
            ) === get(profile, "_id");
          return (
            <div className={`pb-1 `} key={index}>
              <div className=" flex justify-between items-center">
                {isSender || isReceiver ? (
                  <React.Fragment>
                    <span
                      className={`inline-block font-bold text-md ${
                        isSender ? "text-red-500" : ""
                      } ${isReceiver ? "text-green-500" : ""}`}
                    >
                      {isSender
                        ? t("transferTo", sourceKey.asset)
                        : t("receiveFrom", sourceKey.asset)}
                    </span>

                    <span
                      className={`inline-block font-bold text-md ${
                        isSender ? "text-red-500" : ""
                      } ${isReceiver ? "text-green-500" : ""}`}
                    >
                      {isSender ? "-" : "+"}
                      {formatDecimalNumber(
                        get(item, "amount"),
                        numberDecimal
                      )}{" "}
                      USDT
                    </span>
                  </React.Fragment>
                ) : null}
              </div>
              <div className="flex items-center">
                <span className="inline-block text-xs mr-2">
                  {get(
                    item,
                    `${defaultPopulatedFieldName(
                      "transferTransactionId"
                    )}.${defaultPopulatedFieldName(
                      isSender ? "receiverId" : "senderId"
                    )}.${defaultPopulatedFieldName("userId")}.referralCode`
                  )}
                </span>
                <span className="inline-block text-xs ">
                  {get(
                    item,
                    `${defaultPopulatedFieldName(
                      "transferTransactionId"
                    )}.${defaultPopulatedFieldName(
                      isSender ? "receiverId" : "senderId"
                    )}.${defaultPopulatedFieldName("userId")}.username`
                  )}
                </span>
              </div>
              <div className="flex items-center">
                <span className="inline-block text-xs mr-2">
                  {getFormatedDate(get(item, "createdAt"), true)}
                </span>
              </div>
            </div>
          );
        });
      },
    },
    {
      labelCn: "all",
      label: t("all", sourceKey.asset),
      value: "all",
      getData: async (skip) => {
        return await getUserTransactions(PAGESIZE, skip, {
          userId: get(user, "pairingUser._id") || "",
          amountType: 0,
          populate: [
            {
              service: "bonus",
              foreignId: "bonusId",
              populate: [
                // {
                //   service: "rankings",
                //   foreignId: "rankingId",
                // },
                {
                  service: "users",
                  foreignId: "buyerId",
                },
              ],
            },
            {
              service: "bonusleaders",
              foreignId: "bonusLeaderId",
              populate: [
                {
                  service: "users",
                  foreignId: "distributorId",
                },
              ],
            },
            {
              service: "bonuspairings",
              foreignId: "bonusPairingId",
              populate: [
                {
                  service: "placements",
                  foreignId: "placementId",
                },
              ],
            },
            {
              service: "topups",
              foreignId: "topUpId",
            },
            {
              service: "user-transfers",
              foreignId: "transferId",
              populate: [
                {
                  service: "users",
                  foreignId: "senderId",
                },
                {
                  service: "users",
                  foreignId: "receiverId",
                },
              ],
            },
          ],
        });
      },
      render: (data) => {
        // console.log("all data", data);
        return map(data, (item, index) => {
          return (
            <div className={`pb-2`} key={index}>
              <div
                className={`flex justify-between items-center font-bold ${
                  get(item, "trxType") === "IN"
                    ? get(item, "amount") < 0
                      ? "text-red-500"
                      : "text-green-500"
                    : "text-red-500"
                }`}
              >
                <div>
                  {get(item, "populatedClientId") &&
                  get(item, "populatedClientId.isAdminTopUp") === 1
                    ? get(item, "amount") < 0
                      ? t("adminRecall", sourceKey.asset)
                      : t("adminTopUp", sourceKey.asset)
                    : t(
                        placementTransactionDesc[get(item, "sourceType")],
                        sourceKey.asset
                      )}
                  {}
                </div>
                <div>
                  {get(item, "trxType") === "IN"
                    ? get(item, "amount") < 0
                      ? ""
                      : "+ "
                    : "- "}{" "}
                  {formatDecimalNumber(get(item, "amount") || 0, numberDecimal)}{" "}
                  USDT
                </div>
              </div>
              {/* <div className="flex items-center mt-2">
                <span className="inline-block text-xs mr-2">asdasd:</span>
                <span className="inline-block text-xs "> asdasd</span>
              </div> */}
              {renderRecordDetails(item)}
              <div className="flex items-center ">
                <span className="inline-block text-xs mr-2">
                  {getFormatedDate(get(item, "createdAt"), true)}
                </span>
              </div>
            </div>
          );
        });
      },
    },
    // {
    //   labelCn : '一键平仓',
    //   label: ' Triggered Forced Liquidation',
    //   value: 'liquidation',
    // }
  ];
  useEffect(() => {
    getProfileData();
    getPairingUserData();
  }, []);

  useEffect(() => {
    getProfileData();
  }, [props.user.user]);

  useEffect(() => {
    let page = isNaN(toNumber(get(dataPagination, `${logType}.page`)))
      ? 1
      : toNumber(get(dataPagination, `${logType}.page`));
    getData(logType, (page - 1) * PAGESIZE);
  }, [logType, profile]);

  useEffect(() => {
    if (!isEmpty(profile)) {
      // if (!checkIsSufficientValidBalance(profile)) {
      //   props.triggerModal({ type: "appBalance" });
      // }
    }
  }, [profile]);

  function getProfileData() {
    getProfiles(1, 0, { _id: get(user, "profile._id") })
      .then((res) => {
        setProfile(get(res, "data[0]") || {});
      })
      .catch((err) => {});
  }

  function getPairingUserData() {
    getPairingUser(1, 0, { _id: get(user, "profile.pairingUserId") }, accessKey)
      .then((res) => {
        setPairingUser(get(res, "data[0]") || {});
      })
      .catch((err) => {});
  }

  async function getData(logType, skip, page) {
    if (get(profile, "_id")) {
      setArrayLoading(true);

      let newDataPagination = dataPagination;
      let selectedItem = find(filterItems, ["value", logType]);
      if (selectedItem) {
        clearTimeout(timeoutFunc);
        timeoutFunc = setTimeout(async () => {
          try {
            let result = await selectedItem.getData(skip);
            // console.log(result);
            set(
              newDataPagination,
              `${logType}.dataSource`,
              get(result, "data") || []
            );
            set(
              newDataPagination,
              `${logType}.total`,
              get(result, "total") || 0
            );
            set(newDataPagination, `${logType}.page`, page || 1);
            setArrayLoading(false);
            setDataPagination(newDataPagination);
          } catch (error) {
            console.log(error);
            setArrayLoading(false);
          }
        }, 300);
      }
    }
  }

  const _renderList = (data, type) => {
    let selectedItem = find(filterItems, ["value", type]);

    if (arrayLoading) {
      return <Skeleton active rows={5}></Skeleton>;
    }

    if (
      !isArray(data) ||
      isEmpty(data) ||
      !some(filterItems, ["value", type]) ||
      !selectedItem
    ) {
      // return <img src={noData} alt="no-data" />;
      return (
        <div className="items-center justify-center mt-10 ">
          <img
            src={not_foundIcon}
            className=" object-contain h-full w-full"
            style={{ height: "15vh" }}
          />

          <br></br>
          <div className="flex justify-center items-center">
            <div
              className="flex justify-center items-center font-black text-lg text-center mb-2 text-gray-400"
              style={{ width: 310 }}
            >
              {t("noRecords", sourceKey.highFrequency)}
            </div>
          </div>
        </div>
      );
    }

    if (selectedItem) {
      return selectedItem.render(data);
    }
  };

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={arrayLoading}>
          <NavHeader
            suffix={
              <div className="justify-end flex items-center">
                {/* 资产 */}
                {t("asset", sourceKey.asset)}
              </div>
            }
            showBack={true}
            onBack={() => {
              navigate("/");
              // if (location.state.prevPath === undefined) {
              //   navigate("/");
              // } else {
              //   navigate(-1);
              // }
            }}
          >
            <div className="pb-5 mb-2 mr-5 ml-5 ">
              <div
                className="border-b shadow-md pt-4  bg-main-color-gradient"
                style={{
                  borderRadius: 40,
                }}
              >
                <div className="flex justify-center items-center my-1">
                  <span className="inline-block w-1/2 mr-2">
                    <Textfit className="flex justify-center items-center text-xl text-center font-semibold">
                      {isEmpty(profile) ? (
                        <Skeleton.Button active={true} size="small" />
                      ) : (
                        formatDecimalNumber(
                          get(pairingUser, "wallet") || 0,
                          numberDecimal
                        )
                      )}
                    </Textfit>
                    <div className="flex justify-center items-center text-center text-xs">
                      USDT
                    </div>
                  </span>
                  {/* <span className="inline-block w-1/2 mr-2">
                    <div className="flex justify-center items-center text-xl text-center font-semibold">
                      {isEmpty(profile) ? (
                        <Skeleton.Button active={true} size="small" />
                      ) : (
                        formatDecimalNumber(
                          get(profile, "balanceVsdt"),
                          roundingDecimal
                        )
                      )}
                    </div>
                    <div className="flex justify-center items-center text-center text-xs">
                      赠金 VST
                    </div>
                  </span> */}
                </div>
                {/* <div className="flex justify-between items-center my-1">
                  <span className="inline-block w-1/2 mr-2">
                    <div className="flex justify-center items-center text-xl text-center font-semibold">
                      {isEmpty(profile) ? (
                        <Skeleton.Button active={true} size="small" />
                      ) : (
                        formatDecimalNumber(
                          get(profile, "balanceVoucher"),
                          roundingDecimal
                        )
                      )}
                    </div>
                    <div className="flex justify-center items-center text-center text-xs">
                      优惠劵 Voucher
                    </div>
                  </span>
                  <span className="inline-block w-1/2">
                    <div className="flex justify-center items-center text-xl font-semibold">
                      {isEmpty(profile) ? (
                        <Skeleton.Button active={true} size="small" />
                      ) : (
                        get(profile, "expiredAt")
                      )}
                    </div>
                    <div className="flex justify-center items-center text-center text-xs">
                      天数 Days
                    </div>
                  </span>
                </div> */}
                <div className="flex justify-between items-center my-1">
                  {/* <span className='inline-block w-1/2' >
                    <div className="flex justify-center items-center text-xl text-center font-semibold">
                      {formatNumber(get(profile, 'balanceUsdt'), rounding, roundingDecimal, 4, false)}
                    </div>
                    <div className="flex justify-center items-center text-center text-xs">
                      返还服务费 RSP
                    </div>
                  </span> */}
                </div>
              </div>
              <div className="grid grid-cols-6 gap-2 mt-2">
                {map(tabItems, (item, index) => {
                  return (
                    <div className="col-span-2 rounded-xl touchbox-bg-color box-shadow-center flex justify-center mx-1 py-4 w-fit">
                      <Link
                        to={item.path}
                        state={{ prevPath: location.pathname }}
                        key={`menu-${index}`}
                      >
                        <div className="text-center my-auto sm:p-2">
                          <div className="text-base text-white">
                            {item.text}
                          </div>
                          {/* <div className="text-base text-white">
                            {item.textEn}
                          </div> */}
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>

              <Tabs
                centered
                defaultActiveKey={"all"}
                onChange={(activeKey) => {
                  setLogType(activeKey);
                }}
                style={{ color: "black" }}
              >
                {map(filterItems, (item, index) => {
                  return (
                    <Tabs.TabPane
                      tab={
                        <div className="text-center">
                          {/* {get(item, "labelCn")}
                          <br /> */}
                          {get(item, "label")}
                        </div>
                      }
                      key={get(item, "value")}
                    >
                      {_renderList(
                        get(dataPagination, `${logType}.dataSource`),
                        logType
                      )}
                      <div className="flex justify-end items-center my-5">
                        <span className="inline-block ">
                          <Pagination
                            simple
                            size="small"
                            total={get(dataPagination, `${logType}.total`) || 0}
                            pageSize={PAGESIZE}
                            current={get(dataPagination, `${logType}.page`)}
                            showSizeChanger={false}
                            onChange={(page) => {
                              let changeDataPagination = dataPagination;
                              set(
                                changeDataPagination,
                                `${logType}.page`,
                                page || 1
                              );
                              setDataPagination(changeDataPagination);
                              getData(logType, (page - 1) * PAGESIZE, page);
                            }}
                          />
                        </span>
                      </div>
                    </Tabs.TabPane>
                  );
                })}
              </Tabs>
            </div>
          </NavHeader>
        </Spin>
      </Layout>
      <FilterModal
        visible={showFilterModal}
        onClose={() => {
          setShowFilterModal(false);
        }}
        onSubmit={(values) => {
          if (values) {
            setLogTitle(values);
            setLogType(get(values, "value"));
          }
        }}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  triggerModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(RobotPage);
