import { get, isArray, isEmpty, map } from "lodash";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { connect } from "react-redux";
import { arrayLengthCount } from "../../../utilities/common-function";

let timeout;
// markup
const ScrollPicker = (props) => {
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState({});
  const [height, setHeight] = useState(100);
  const [itemHeight, setItemHeight] = useState(50);
  const [totalHeight, setTotalHeight] = useState(100);

  useEffect(() => {
    setData(isArray(props.data) && !isEmpty(props.data) ? props.data : []);
  }, [props.data]);

  useEffect(() => {
    setHeight(props.height);
  }, [props.height]);

  useEffect(() => {
    setItemHeight(props.itemHeight);
  }, [props.itemHeight]);

  useEffect(() => {
    setSelected(get(data, "[0]"));
  }, [data]);

  useEffect(() => {
    setTotalHeight(parseFloat(itemHeight) * arrayLengthCount(data));
  }, [data, itemHeight]);

  useEffect(() => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (props.onChange) {
        props.onChange(selected);
      }
    }, 500);
  }, [selected]);

  function handleScroll(values) {
    let dataLength = arrayLengthCount(data) || 0;
    let scrollHeight = values.scrollHeight - values.clientHeight;
    let scrollTop = values.scrollTop || 1;
    let scrollPercentage = scrollTop / scrollHeight;
    let currentIndex = parseInt(dataLength * scrollPercentage);
    if (currentIndex >= dataLength) {
      currentIndex = dataLength - 1;
    }
    if (currentIndex < 0) {
      currentIndex = 0;
    }

    setSelected(get(data, `[${currentIndex}]`));
  }

  return (
    <React.Fragment>
      <Scrollbars
        autoHide
        autoHeight
        autoHeightMax={height}
        onScrollFrame={handleScroll}
      >
        {map(data, (item, index) => {
          return (
            <div
              className={`${
                get(item, "value") === get(selected, "value")
                  ? "font-semibold text-base"
                  : "font-thin text-sm"
              }`}
              key={index}
              style={{ height: itemHeight }}
              onClick={(e) => {
                setSelected(item);
              }}
            >
              {item.label}
            </div>
          );
        })}
      </Scrollbars>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ScrollPicker);
