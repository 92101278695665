import * as React from "react";
import SharedHeader from "../components/general/components/SharedHeader";
import AssetPage from "../components/robot/pages/AssetPage";

// markup
const Asset = () => {
  return (
    <React.Fragment>
      <SharedHeader />
      <AssetPage />
    </React.Fragment>
  );
};

export default Asset;
