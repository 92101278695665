import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import ScrollPicker from "./ScrollPicker";

const filterItems = [
  {
    label: "服务费 Service Charge",
    value: "serviceCharge",
  },
  {
    label: "提币 Withdrawal",
    value: "withdrawal",
  },
  {
    label: "充值 Deposit",
    value: "deposit",
  },
  {
    label: "互转 Transfer",
    value: "transfer",
  },

  {
    label: "社区奖励 Rewards",
    value: "reward",
  },
  {
    label: "激活码 Activation Code",
    value: "activationCode",
  },
  {
    label: "后台充值 Back Office Top Up",
    value: "adminTopUpDeposit",
  },
  {
    label: "一键平仓 Triggered Forced Liquidation",
    value: "liquidation",
  },
];

const MsgModal = (props) => {
  const [visible, setVisible] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(0);

  useEffect(() => {
    setVisible(props.visible === true);
  }, [props.visible]);

  function close() {
    if (props.onClose) {
      props.onClose();
    } else {
      setVisible(false);
    }
  }

  function handelSubmit(s) {
    if (props.onSubmit) {
      props.onSubmit(s);
      close();
    }
  }

  return (
    <React.Fragment>
      <Modal
        className="no-padding-modal-body modal-body-background-transparent"
        centered
        maskClosable
        width="90%"
        mask
        footer={null}
        closable={false}
        visible={visible}
        onCancel={() => {
          close();
        }}
      >
        <div
          className="bg-white rounded-3xl pb-10 text-center pt-5 px-0"
          style={{ borderRadius: 15 }}
        >
          <span className="inline-block text-sm font-semibold mb-5">
            选择 Selection
          </span>
          <ScrollPicker
            data={filterItems}
            itemHeight={50}
            height={150}
            onChange={(value) => {
              setSelectedFilter(value);
            }}
          ></ScrollPicker>
          <div className="flex justify-around mt-10">
            <span
              className="rounded-md bg-main-color-gradient px-3 py-1.5"
              onClick={() => {
                handelSubmit(selectedFilter);
              }}
            >
              确认 Confirm
            </span>
            <span
              className="text-red-500 px-3 py-1.5 border border-red-500 rounded-md"
              onClick={close}
            >
              取消 Cancel
            </span>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default MsgModal;
